import React, { memo } from "react";
import style from "styles/team.module.css";
// import babar from "public/image/Babar.webp";
// import david from "public/image/DavidAtkinson.webp"
// import hasan from "public/image/Hassan.webp"
// import iftikhar from "public/image/Iftikhar.webp"
import konstantin from "public/image/team/konstantin1.png";
import babar from "public/image/team/babar.png";
import david from "public/image/team/david.png"
import hasan from "public/image/team/hatu.png"
import iftikhar from "public/image/team/ifty.png"
import serge from "public/image/team/serge.png"
import heshuying from "public/image/Heshuying.webp"
import Image from "next/image";
import Link from 'next/link'
import Linkedin from '../Team/socials/linkedin'
import Elm from '../Team/socials/elm'
import Wiki from '../Team/socials/wiki'
import Git from '../Team/socials/git'
import Gamicus from '../Team/socials/gamicus'
import Telegram from '../Team/socials/telegram'
import Imdb from '../Team/socials/imdb'
import useLocale from '../../../../hooks/useLocale'

const Advisory = () => {
    const {advisorySection} = useLocale()
    const teamList = [
        {
            icon: hasan,
            name: advisorySection.hassan.name,
            profession: advisorySection.hassan.profession,
            about: advisorySection.hassan.about,
            link: "https://www.linkedin.com/in/hassan-hatu-sheikh-182a94a8",
            type: "linkedin",
        },
        {
            icon: serge,
            name: advisorySection.serge.name,
            profession: advisorySection.serge.profession,
            // about: advisorySection.serge.about,
            link: "https://www.imdb.com/name/nm1965298/",
            type: "imdb"
        },
        {
            icon: konstantin,
            name: advisorySection.konstantin.name,
            profession: advisorySection.konstantin.profession,
            about: advisorySection.konstantin.about,
        },

        {
            icon: david,
            name: advisorySection.david.name,
            profession: advisorySection.david.profession,
            about: advisorySection.david.about,
            link: "https://t.me/dcatki",
            type: "telegram",
        },
        //{
        //     icon: heshuying,
        //     name: advisorySection.andrey.name,
        //     profession: advisorySection.andrey.profession,
        //     about: advisorySection.andrey.about,
        // },

    ]
    return (
        <div className={style.team}>
            <div className={style.container}>
                <h2 className={style.title} style={{ marginBottom: 32 }} data-aos-once="true" data-aos="slide-up">{advisorySection.title}</h2>
                <div className={`grid ${style.blockTeam}`} data-aos-once="true" data-aos="slide-up" data-aos-anchor-placement={"center"} data-aos-duration={300}>
                    {teamList.map((item, index) =>
                        <div key={`item_${index}`} className={style.teammate}>
                            <div className={style.blockImg}>
                                <div className={style.img}>
                                    <Image
                                        src={item.icon}
                                        alt=""
                                        layout='responsive'
                                        objectFit='contain'
                                    />
                                </div>
                                {item.link && (
                                    <Link href={item.link}>
                                        <a target="_blank" className={style.float} aria-label="team">
                                            {item.type === 'linkedin' && (
                                                <Linkedin width="100%" height="100%" />
                                            )}
                                            {item.type === 'elm' && (
                                                <Elm width="100%" height="100%" />
                                            )}
                                            {item.type === 'wiki' && (
                                                <Wiki width="100%" height="100%" />
                                            )}
                                            {item.type === 'github' && (
                                                <Git width="100%" height="100%" />
                                            )}
                                            {item.type === 'gamicus' && (
                                                <Gamicus width="100%" height="100%" />
                                            )}
                                            {item.type === 'telegram' && (
                                                <Telegram width="100%" height="100%" />
                                            )}
                                            {item.type === 'imdb' && (
                                                <Imdb width="100%" height="100%" />
                                            )}
                                        </a>
                                    </Link>
                                )}
                            </div>
                            <h2 className={style.name}>{item.name}</h2>
                            <p className={style.profession}>{item.profession}</p>
                            {item.about && (
                                <p className={style.about}>{item.about}</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default memo(Advisory);
