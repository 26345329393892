import React from "react"

interface types {
	width?: number | string | undefined;
	height?: number | string | undefined;
}

const Telegram = ({width = 41, height = 42}: types) => {
	return (
		<svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd"
				  d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16ZM13.0667 23.3333L13.3389 19.2548L13.3387 19.2547L20.7579 12.5593C21.0836 12.2703 20.6868 12.1294 20.2546 12.3915L11.0982 18.1682L7.14312 16.9338C6.289 16.6723 6.28287 16.0853 7.33488 15.6634L22.7469 9.72058C23.4508 9.401 24.1302 9.88964 23.8615 10.967L21.2368 23.3354C21.0535 24.2143 20.5225 24.4245 19.7867 24.0185L15.7885 21.0646L13.8667 22.9333C13.8606 22.9392 13.8546 22.9451 13.8486 22.9509C13.6336 23.1602 13.4558 23.3333 13.0667 23.3333Z"
				  fill="white"/>
		</svg>

	)
}

export default (Telegram)
